<template>
  <div id="one-click-login">
    <img
        src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/one-click-login/background.png"
        alt=""
    />
    <div class="phone">187****0068</div>
    <div
        class="login-button"
        @click="doLogin(0)"
    >
      一键登录去借款
    </div>
    <div
        class="login-button login-button-not-active"
        @click="doLogin(1)"
    >
      登录其他账号
    </div>
    <div class="protocol-container">
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/api-product-middle-page/protocol-is-not-select.png"
          alt=""
          v-show="!protocolIsSelected"
          @click="protocolIsSelected = !protocolIsSelected"
      />
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/api-product-middle-page/protocol-is-selected.png"
          alt=""
          v-show="protocolIsSelected"
          @click="protocolIsSelected = !protocolIsSelected"
      />
      我已阅读并同意
      <em @click="showProtocolContentPopup(0)">《软件注册协议》</em>
      <em @click="showProtocolContentPopup(1)">《隐私协议》</em>
    </div>
    <van-popup
        round
        v-model="protocolContentPopupIsShow"
    >
      <div class="protocol-content-popup-container">
        <div
            class="protocol-content"
            v-html="protocolContentHtml"
        >
        </div>
        <div
            class="submit-button"
            @click="protocolContentPopupIsShow = false"
        >
          好的
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { get_agreement_list } from '_network/submitInfo'

export default {
  data() {
    return {
      // 协议勾选
      protocolIsSelected: false,
      // 协议展示弹框
      protocolContentPopupIsShow: false,
      // 协议内容(富文本)
      protocolContentHtml: '',
      // 注册协议+隐私协议集合(富文本)
      protocolContentHtmlList: ['', '']
    }
  },
  methods: {
    // 获取协议内容
    async getProtocolContentHtmlList() {
      try {
        const { data } = await get_agreement_list()
        data.forEach((item) => {
          if (item.name === "用户注册协议") {
            this.protocolContentHtmlList[0] = item.content
          }
          if (item.name === "隐私协议") {
            this.protocolContentHtmlList[1] = item.content
          }
        })
      } catch (e) {}
    },
    /**
     * @description 展示协议内容弹框
     * @param index 0为注册协议 1为隐私协议
     * */
    showProtocolContentPopup(index) {
      this.protocolContentHtml = this.protocolContentHtmlList[index]
      this.protocolContentPopupIsShow = true
    },
    /**
     * @description 用户点击登录按钮
     * @param type 0一键登录 1登录其他账号
     * */
    doLogin(type) {
      if (type === 0) {

      } else {
        this.$router.replace('/register')
      }
    }
  },
  created() {
    this.getProtocolContentHtmlList()
  }
}
</script>

<style lang="less" scoped>
#one-click-login {
  min-height: 100vh;
  padding: 0.48rem 0.427rem 0;
  background: #FFFFFF;

  .phone {
    margin: 1.173rem 0 1.333rem;
    font-weight: bold;
    font-size: 0.773rem;
    color: #2E2F31;
    text-align: center;
  }

  .login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8.4rem;
    height: 1.333rem;
    margin: 0 auto 0.32rem;
    background: #366DF7;
    border-radius: 0.667rem;
    font-weight: 500;
    font-size: 0.427rem;
    color: #FFF;
  }

  .login-button-not-active {
    background: #FFF;
    border: 1px solid #979797;
    color: #333333;
  }

  .protocol-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.64rem 0 0.48rem;
    font-size: 0.32rem;
    color: #666666;

    img {
      width: 0.427rem;
      height: 0.427rem;
      margin-right: 0.133rem;
    }

    em {
      color: #317CFF;
    }
  }

  .protocol-content-popup-container {
    width: 9.067rem;
    padding: 0.427rem;

    .protocol-content {
      overflow-y: auto;
      width: 100%;
      height: 8.7838rem;
    }

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.333rem;
      margin-top: 0.427rem;
      background: #325EF3;
      border-radius: 0.667rem;
      font-size: 0.48rem;
      color: #FFFFFF;
    }
  }
}
</style>